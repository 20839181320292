(function () {
  const screenHeight = window.innerHeight;
  const fvCcontainer = document.querySelector('.fv-container');
  const fvCcontainerHeight = fvCcontainer.clientHeight;
  const improvementVideoFeild = document.querySelector('.improvement-video__field');
  const nav = document.querySelector('.nav-container');
  const header = document.querySelector('.header-container');
  const hamburgerMenu = document.querySelector('.hamburger-menu-container');
  const hamburgerMenuBorder = document.querySelector('.hamburger-menu__border');
  const hamburgerMenuBtnOpen = document.querySelectorAll('.gloval-nav__hamburger-menu-btn');
  const hamburgerMenuBtnClose = document.querySelector('.hamburger-menu__close-btn');
  const highlightTexts = document.querySelectorAll('.highlight');
  const withdrawalRateGraph = document.querySelector('.withdrawal-rate__blockquote');
  window.addEventListener('scroll', () => {
    const scroll = window.scrollY;
    const improvementVideoFeildHeight = improvementVideoFeild.getBoundingClientRect().top;
    const withdrawalRateGraphHeight = withdrawalRateGraph.getBoundingClientRect().top;
    if (scroll > fvCcontainerHeight) {
      nav.classList.add('animation');
      header.style.display = 'none';
    }
    if (improvementVideoFeildHeight - screenHeight / 4 < 0) {
      playVideo();
    }
    if (withdrawalRateGraphHeight - (screenHeight / 3) * 2 < 0) {
      withdrawalRateGraph.classList.add('animation');
    }
    for (let i = 0; i < highlightTexts.length; i++) {
      if (highlightTexts[i].getBoundingClientRect().top - (screenHeight / 3) * 2 < 0) {
        highlightTexts[i].classList.add('animation');
      }
    }
  });
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener('click', (e) => {
      e.preventDefault();
      const href = smoothScrollTrigger[i].getAttribute('href');
      const targetElement = document.getElementById(href.replace('#', ''));
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const gap = 70;
      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    });
  }
  const playVideo = () => {
    improvementVideoFeild.muted = true;
    improvementVideoFeild.play();
  };

  for (let i = 0; i < hamburgerMenuBtnOpen.length; i++) {
    hamburgerMenuBtnOpen[i].addEventListener('click', (e) => {
      e.preventDefault();
      hamburgerMenu.classList.add('open');
      hamburgerMenu.setAttribute('aria-hidden', false);
    });
  }
  document.addEventListener('click', (e) => {
    const target = e.target.className;
    if ([hamburgerMenuBtnClose.className, hamburgerMenu.className, hamburgerMenuBorder.className].includes(target)) {
      e.preventDefault();
      hamburgerMenu.classList.remove('open');
      hamburgerMenu.setAttribute('aria-hidden', true);
    }
  });
})();
